import * as BUI from '@thatopen/ui';
import { FileTree, Local, Project, Discipline } from '../../types/fileTree';
import { ModelLoader } from '../../models/modelLoader';
import { getFileTreeUtils } from '../../utils/fileTreeUtils';
import { ProjectUI } from './ProjectUI';

export class ProjectTreeUI {
    private modelLoader: ModelLoader;
    private fileCheckboxes: Map<string, HTMLElement> = new Map();
    private projectCheckboxes: Map<string, HTMLElement> = new Map();
    private disciplineCheckboxes: Map<string, HTMLElement> = new Map();
    private fileSpinners: Map<string, HTMLElement> = new Map();
    private projectSpinners: Map<string, HTMLElement> = new Map();
    private disciplineSpinners: Map<string, HTMLElement> = new Map();
    private currentLocalId: string | null = null;
    private fileTreeUtils: ReturnType<typeof getFileTreeUtils>;
    private loadingErrors: Map<string, Error> = new Map();
    private loadingFiles: Set<string> = new Set();
    private spinnerUpdateInterval: number | null = null;

    constructor(fileTree: FileTree, modelLoader: ModelLoader) {
        this.modelLoader = modelLoader;
        this.fileTreeUtils = getFileTreeUtils(fileTree);
        this.startSpinnerMonitoring();
    }

    private startSpinnerMonitoring() {
        if (this.spinnerUpdateInterval !== null) {
            window.clearInterval(this.spinnerUpdateInterval);
        }
        this.spinnerUpdateInterval = window.setInterval(() => {
            this.updateSpinnerStates();
        }, 500);
    }

    private updateSpinnerStates() {
        const loadedModels = this.modelLoader.getLoadedModels();
        for (const fileId of this.loadingFiles) {
            if (loadedModels.has(fileId)) {
                this.hideFileSpinner(fileId);
            }
        }
    }

    private showFileSpinner(fileId: string) {
        const spinner = this.fileSpinners.get(fileId);
        const checkbox = this.fileCheckboxes.get(fileId);
        if (spinner) spinner.style.display = 'block';
        if (checkbox) checkbox.setAttribute('disabled', 'true');
        this.loadingFiles.add(fileId);
    }

    private hideFileSpinner(fileId: string) {
        const spinner = this.fileSpinners.get(fileId);
        const checkbox = this.fileCheckboxes.get(fileId);
        if (spinner) spinner.style.display = 'none';
        if (checkbox) checkbox.removeAttribute('disabled');
        this.loadingFiles.delete(fileId);
    }

    private showProjectSpinner(projectId: string) {
        const spinner = this.projectSpinners.get(projectId);
        const checkbox = this.projectCheckboxes.get(projectId);
        if (spinner) {
            spinner.style.display = 'block';
            spinner.style.zIndex = '10';
        }
        if (checkbox) checkbox.setAttribute('disabled', 'true');
    }

    private hideProjectSpinner(projectId: string) {
        const spinner = this.projectSpinners.get(projectId);
        const checkbox = this.projectCheckboxes.get(projectId);
        if (spinner) spinner.style.display = 'none';
        if (checkbox) checkbox.removeAttribute('disabled');
    }

    private showDisciplineSpinner(disciplineId: string) {
        const spinner = this.disciplineSpinners.get(disciplineId);
        const checkbox = this.disciplineCheckboxes.get(disciplineId);
        if (spinner) {
            spinner.style.display = 'block';
            spinner.style.zIndex = '10';
        }
        if (checkbox) checkbox.setAttribute('disabled', 'true');
    }

    private hideDisciplineSpinner(disciplineId: string) {
        const spinner = this.disciplineSpinners.get(disciplineId);
        const checkbox = this.disciplineCheckboxes.get(disciplineId);
        if (spinner) spinner.style.display = 'none';
        if (checkbox) checkbox.removeAttribute('disabled');
    }

    public generateLocalsHTML() {
        const sortedLocals = this.fileTreeUtils.getSortedLocals();
        const options = [
            { value: 'select', label: 'Selecione o local', selected: true },
            ...sortedLocals.map(local => ({
                value: local.id.toString(),
                label: local.name,
                selected: false,
            })),
        ];

        return BUI.html`
      <bim-panel-section fixed label="Locais" icon="bi:buildings" style="display: flex; flex-direction: column; overflow: visible !important; height: auto !important; min-height: 100px;">
        <select id="selecaoLocal" class="dropdown-local" name="selecaoLocal" @change=${this.handleLocalChange.bind(this)} style="margin-bottom: 10px;">
          ${options.map(option => BUI.html`
            <option value="${option.value}" ?selected=${option.selected}>
              ${option.label}
            </option>
          `)}
        </select>
        <div id="projects-container" style="overflow: visible !important; width: 100%; position: relative;"></div>
      </bim-panel-section>
    `;
    }

    private handleLocalChange(event: Event) {
        const dropdown = event.target as HTMLSelectElement;
        const selectedLocalId = dropdown.value;
        this.modelLoader.unloadAllModels(this.fileCheckboxes);
        this.currentLocalId = selectedLocalId !== 'select' ? selectedLocalId : null;

        const projectsContainer = this.findProjectsContainer();
        if (!projectsContainer) return;

        this.clearProjectsContainer(projectsContainer);

        if (this.isValidLocalSelection(selectedLocalId)) {
            const selectedLocal = this.fileTreeUtils.findLocalById(selectedLocalId);
            if (selectedLocal) {
                this.renderProjectsForLocal(selectedLocal, projectsContainer);
            }
        }
        this.adjustContainerHeight();
    }

    private findProjectsContainer() {
        const container = document.getElementById('projects-container');
        if (!container) console.error('Container de projetos não encontrado');
        return container;
    }

    private clearProjectsContainer(container: HTMLElement) {
        while (container.firstChild) {
            container.removeChild(container.firstChild);
        }
    }

    private isValidLocalSelection(localId: string) {
        return localId && localId !== 'select';
    }

    private renderProjectsForLocal(local: Local, container: HTMLElement) {
        container.style.overflow = 'visible';
        container.style.display = 'flex';
        container.style.flexDirection = 'column';
        container.style.width = '100%';
        container.style.position = 'relative';

        const sortedProjects = this.fileTreeUtils.getSortedProjectsForLocal(local.id.toString());
        for (const project of sortedProjects) {
            const projectUI = new ProjectUI(
                project,
                this.fileCheckboxes,
                this.projectCheckboxes,
                this.disciplineCheckboxes,
                this.fileSpinners,
                this.projectSpinners,
                this.disciplineSpinners,
                this.handleProjectCheckboxChange.bind(this),
                this.handleDisciplineCheckboxChange.bind(this),
                this.handleFileCheckboxChange.bind(this),
                this.adjustContainerHeight.bind(this)
            );
            container.appendChild(projectUI.createElement());
        }
        this.adjustContainerHeight();
    }

    private adjustContainerHeight() {
        setTimeout(() => {
            const projectsContainer = document.getElementById('projects-container');
            if (projectsContainer) {
                let totalHeight = 0;
                const projectElements = projectsContainer.querySelectorAll('div[style*="backgroundColor: rgba(128, 128, 128, 0.05)"]');
                projectElements.forEach(project => {
                    totalHeight += project.scrollHeight;
                });
                totalHeight += 50;

                const panelSection = projectsContainer.closest('bim-panel-section');
                if (panelSection) {
                    panelSection.style.height = 'auto';
                    panelSection.style.minHeight = `${totalHeight}px`;
                    panelSection.style.overflow = 'visible';
                }

                const panel = projectsContainer.closest('bim-panel');
                if (panel) {
                    panel.style.overflow = 'visible';
                    panel.style.minHeight = `${totalHeight + 100}px`;
                }
            }
        }, 300);
    }

    private handleProjectCheckboxChange(project: Project, checked: boolean) {
        const projectId = project.id.toString();
        try {
            if (checked) {
                this.showProjectSpinner(projectId);
                const loadPromises: Promise<void>[] = [];
                const nonDisciplineFiles = project.files.filter(file => {
                    const fileId = file.id.toString();
                    return !file.discipline_id && !this.modelLoader.getLoadedModels().has(fileId);
                });
                nonDisciplineFiles.forEach(file => this.showFileSpinner(file.id.toString()));
                if (nonDisciplineFiles.length > 0) {
                    loadPromises.push(
                        this.modelLoader.loadProjectFiles(
                            nonDisciplineFiles,
                            this.fileCheckboxes,
                            (_fileId, fileCheckbox) => fileCheckbox.setAttribute('checked', 'true'),
                            (fileId, fileCheckbox, success) => {
                                if (!success) fileCheckbox.removeAttribute('checked');
                                this.hideFileSpinner(fileId);
                            }
                        )
                    );
                }
                if (project.disciplines && project.disciplines.length > 0) {
                    for (const discipline of project.disciplines) {
                        const unloadedFiles = discipline.files.filter(file => !this.modelLoader.getLoadedModels().has(file.id.toString()));
                        unloadedFiles.forEach(file => this.showFileSpinner(file.id.toString()));
                        if (unloadedFiles.length > 0) {
                            const disciplineCheckbox = this.disciplineCheckboxes.get(discipline.id.toString());
                            if (disciplineCheckbox) disciplineCheckbox.setAttribute('checked', 'true');
                            this.showDisciplineSpinner(discipline.id.toString());
                            loadPromises.push(
                                this.modelLoader.loadProjectFiles(
                                    unloadedFiles,
                                    this.fileCheckboxes,
                                    (_fileId, fileCheckbox) => fileCheckbox.setAttribute('checked', 'true'),
                                    (fileId, fileCheckbox, success) => {
                                        if (!success) fileCheckbox.removeAttribute('checked');
                                        this.hideFileSpinner(fileId);
                                    }
                                )
                            );
                        }
                    }
                }
                Promise.all(loadPromises)
                    .catch(error => {
                        console.error(`Erro ao carregar arquivos do projeto ${project.name}:`, error);
                        this.loadingErrors.set(projectId, error);
                    })
                    .finally(() => {
                        this.hideProjectSpinner(projectId);
                        if (project.disciplines) {
                            for (const discipline of project.disciplines) {
                                this.hideDisciplineSpinner(discipline.id.toString());
                            }
                        }
                    });
            } else {
                this.hideProjectSpinner(projectId);
                for (const file of project.files || []) {
                    const fileId = file.id.toString();
                    const fileCheckbox = this.fileCheckboxes.get(fileId);
                    if (fileCheckbox && fileCheckbox.hasAttribute('checked')) {
                        fileCheckbox.removeAttribute('checked');
                        this.modelLoader.unloadIfcById(fileId, file.name);
                        this.hideFileSpinner(fileId);
                    }
                }
                if (project.disciplines) {
                    for (const discipline of project.disciplines) {
                        const disciplineId = discipline.id.toString();
                        const disciplineCheckbox = this.disciplineCheckboxes.get(disciplineId);
                        if (disciplineCheckbox) disciplineCheckbox.removeAttribute('checked');
                        this.hideDisciplineSpinner(disciplineId);
                    }
                }
            }
        } catch (error) {
            console.error(`Erro ao processar o projeto ${project.name}:`, error);
            this.loadingErrors.set(projectId, error as Error);
            this.hideProjectSpinner(projectId);
        }
    }

    private handleDisciplineCheckboxChange(discipline: Discipline, checked: boolean) {
        const disciplineId = discipline.id.toString();
        try {
            if (checked) {
                this.showDisciplineSpinner(disciplineId);
                const unloadedFiles = discipline.files.filter(file => !this.modelLoader.getLoadedModels().has(file.id.toString()));
                unloadedFiles.forEach(file => this.showFileSpinner(file.id.toString()));
                if (unloadedFiles.length > 0) {
                    this.modelLoader.loadProjectFiles(
                        unloadedFiles,
                        this.fileCheckboxes,
                        (_fileId, fileCheckbox) => fileCheckbox.setAttribute('checked', 'true'),
                        (fileId, fileCheckbox, success) => {
                            if (!success) fileCheckbox.removeAttribute('checked');
                            this.hideFileSpinner(fileId);
                        }
                    ).catch(error => {
                        console.error(`Erro ao carregar arquivos da disciplina ${discipline.name}:`, error);
                        this.loadingErrors.set(`discipline_${disciplineId}`, error);
                    }).finally(() => {
                        this.hideDisciplineSpinner(disciplineId);
                    });
                } else {
                    this.hideDisciplineSpinner(disciplineId);
                }
            } else {
                this.hideDisciplineSpinner(disciplineId);
                for (const file of discipline.files) {
                    const fileId = file.id.toString();
                    const fileCheckbox = this.fileCheckboxes.get(fileId);
                    if (fileCheckbox && fileCheckbox.hasAttribute('checked')) {
                        fileCheckbox.removeAttribute('checked');
                        this.modelLoader.unloadIfcById(fileId, file.name);
                        this.hideFileSpinner(fileId);
                    }
                }
            }
        } catch (error) {
            console.error(`Erro ao processar a disciplina ${discipline.name}:`, error);
            this.loadingErrors.set(`discipline_${disciplineId}`, error as Error);
            this.hideDisciplineSpinner(disciplineId);
        }
    }

    private async handleFileCheckboxChange(fileId: string, fileName: string, checked: boolean) {
        const checkbox = this.fileCheckboxes.get(fileId);
        const spinnerIcon = this.fileSpinners.get(fileId);
        const isLoading = checked;

        if (isLoading && spinnerIcon) {
            spinnerIcon.style.display = 'block';
            checkbox?.setAttribute('disabled', 'true');
            this.loadingFiles.add(fileId);
        }

        const fileLocal = this.fileTreeUtils.findLocalByFileId(fileId);
        if (!fileLocal) {
            console.error(`Não foi possível encontrar o local do arquivo: ${fileName}`);
            if (spinnerIcon) spinnerIcon.style.display = 'none';
            checkbox?.removeAttribute('disabled');
            return;
        }

        const fileProject = this.fileTreeUtils.findProjectByFileId(fileId);
        if (!fileProject) {
            console.error(`Não foi possível encontrar o projeto do arquivo: ${fileName}`);
            if (spinnerIcon) spinnerIcon.style.display = 'none';
            checkbox?.removeAttribute('disabled');
            return;
        }

        if (this.currentLocalId !== null && this.currentLocalId !== fileLocal.id.toString() && isLoading) {
            this.modelLoader.unloadAllModels(this.fileCheckboxes);
            this.currentLocalId = fileLocal.id.toString();
        }

        if (this.currentLocalId === null && isLoading) {
            this.currentLocalId = fileLocal.id.toString();
        }

        this.modelLoader.queueFileOperation(fileId, fileName, checkbox!, isLoading);

        if (!isLoading && spinnerIcon) {
            spinnerIcon.style.display = 'none';
            this.loadingFiles.delete(fileId);
        }

        setTimeout(() => {
            if (isLoading && !this.modelLoader.getLoadedModels().has(fileId)) {
                if (spinnerIcon) spinnerIcon.style.display = 'none';
                checkbox?.removeAttribute('disabled');
                this.loadingFiles.delete(fileId);
            }
        }, 10000);
    }

    public dispose() {
        if (this.spinnerUpdateInterval !== null) {
            window.clearInterval(this.spinnerUpdateInterval);
            this.spinnerUpdateInterval = null;
        }
    }
}