import { Discipline } from '../../types/fileTree';
import { FileUI } from './FileUI';

export class DisciplineUI {
	private discipline: Discipline;
	private fileCheckboxes: Map<string, HTMLElement>;
	private disciplineCheckboxes: Map<string, HTMLElement>;
	private fileSpinners: Map<string, HTMLElement>;
	private disciplineSpinners: Map<string, HTMLElement>;
	private onDisciplineCheckboxChange: (discipline: Discipline, checked: boolean) => void;
	private onFileCheckboxChange: (fileId: string, fileName: string, checked: boolean) => void;
	private adjustContainerHeight: () => void;

	constructor(
		discipline: Discipline,
		fileCheckboxes: Map<string, HTMLElement>,
		disciplineCheckboxes: Map<string, HTMLElement>,
		fileSpinners: Map<string, HTMLElement>,
		disciplineSpinners: Map<string, HTMLElement>,
		onDisciplineCheckboxChange: (discipline: Discipline, checked: boolean) => void,
		onFileCheckboxChange: (fileId: string, fileName: string, checked: boolean) => void,
		adjustContainerHeight: () => void
	) {
		this.discipline = discipline;
		this.fileCheckboxes = fileCheckboxes;
		this.disciplineCheckboxes = disciplineCheckboxes;
		this.fileSpinners = fileSpinners;
		this.disciplineSpinners = disciplineSpinners;
		this.onDisciplineCheckboxChange = onDisciplineCheckboxChange;
		this.onFileCheckboxChange = onFileCheckboxChange;
		this.adjustContainerHeight = adjustContainerHeight;
	}

	public createElement(): HTMLElement {
		const disciplineContainer = document.createElement('div');
		disciplineContainer.classList.add('discipline-container');


		const disciplineHeader = document.createElement('div');
		disciplineHeader.classList.add('discipline-header');


		const disciplineLabel = document.createElement('bim-label');
		disciplineHeader.classList.add('discipline-label');
		disciplineLabel.textContent = this.discipline.name;
		disciplineLabel.icon = 'ep:arrow-right-bold';

		const spinnerIcon = document.createElement('bim-icon');
		spinnerIcon.classList.add('spinner-icon');
		spinnerIcon.setAttribute('icon', 'svg-spinners:ring-resize');


		const disciplineId = this.discipline.id.toString();
		this.disciplineSpinners.set(disciplineId, spinnerIcon);

		const disciplineCheckbox = document.createElement('bim-checkbox');
		disciplineCheckbox.name = `discipline_${disciplineId}`;
		disciplineCheckbox.addEventListener('change', () => this.onDisciplineCheckboxChange(this.discipline, disciplineCheckbox.checked));
		disciplineCheckbox.title = 'Carregar todos arquivos da disciplina';
		this.disciplineCheckboxes.set(disciplineId, disciplineCheckbox);

		// Adicionar um observer para sincronizar o estado de disabled do checkbox com a exibição do spinner
		const observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.attributeName === 'style') {
					const isSpinnerVisible = spinnerIcon.style.display === 'block';
					// Simplesmente adiciona ou remove a classe conforme necessário
					disciplineCheckbox.classList.toggle('disabled-checkbox', isSpinnerVisible);
				}
			});
		});

		observer.observe(spinnerIcon, { attributes: true });

		disciplineHeader.appendChild(disciplineLabel);
		disciplineHeader.appendChild(spinnerIcon);
		disciplineHeader.appendChild(disciplineCheckbox);

		const disciplineFilesContainer = document.createElement('div');
		disciplineFilesContainer.classList.add('discipline-files-container');


		let isExpanded = false;
		disciplineHeader.addEventListener('click', (e) => {
			if (e.target === disciplineCheckbox || disciplineCheckbox.contains(e.target as Node)) return;
			isExpanded = !isExpanded;
			if (isExpanded) {
				disciplineFilesContainer.style.display = 'block';
				disciplineFilesContainer.style.maxHeight = 'none';
				const realHeight = disciplineFilesContainer.scrollHeight;
				disciplineFilesContainer.style.maxHeight = '0';
				disciplineFilesContainer.style.overflow = 'hidden';
				disciplineFilesContainer.style.transition = 'max-height 0.3s ease-out';
				void disciplineFilesContainer.offsetHeight;
				disciplineFilesContainer.style.maxHeight = `${realHeight}px`;
				setTimeout(() => {
					disciplineFilesContainer.style.maxHeight = 'none';
					disciplineFilesContainer.style.overflow = 'visible';
					this.adjustContainerHeight();
				}, 300);
			} else {
				const currentHeight = disciplineFilesContainer.scrollHeight;
				disciplineFilesContainer.style.maxHeight = `${currentHeight}px`;
				disciplineFilesContainer.style.overflow = 'hidden';
				disciplineFilesContainer.style.transition = 'max-height 0.3s ease-out';
				void disciplineFilesContainer.offsetHeight;
				disciplineFilesContainer.style.maxHeight = '0';
				setTimeout(() => {
					disciplineFilesContainer.style.display = 'none';
					this.adjustContainerHeight();
				}, 300);
			}
			disciplineLabel.setAttribute('icon', isExpanded ? 'ep:arrow-down-bold' : 'ep:arrow-right-bold');
		});

		const sortedFiles = [...this.discipline.files].sort((a, b) => a.name.localeCompare(b.name));
		for (const file of sortedFiles) {
			const fileUI = new FileUI(file, this.fileCheckboxes, this.fileSpinners, this.onFileCheckboxChange);
			disciplineFilesContainer.appendChild(fileUI.createElement());
		}

		disciplineContainer.appendChild(disciplineHeader);
		disciplineContainer.appendChild(disciplineFilesContainer);
		return disciplineContainer;
	}
}