import { setupDevHotkeys } from '../utils/devUtils';

export class DevEnvironment {
  private static instance: DevEnvironment;
  public isActive: boolean;
  public useMockData: boolean;
  private useJwtInDev: boolean = false;

  private constructor() {
    const isDevelopment = import.meta.env.DEV;
    this.useMockData = isDevelopment && import.meta.env.VITE_USE_MOCK_DATA === 'true';
    this.isActive = isDevelopment;
    
    this.initialize();
  }
  
  public static getInstance(): DevEnvironment {
    if (!DevEnvironment.instance) {
      DevEnvironment.instance = new DevEnvironment();
    }
    return DevEnvironment.instance;
  }
  
  private initialize(): void {
    if (this.useMockData) {
      console.log('🔧 MODO DE DESENVOLVIMENTO: Usando dados mockados, autenticação JWT desabilitada');
      setupDevHotkeys();
    }
  }
  
  public shouldSkipDomainCheck(): boolean {
    return this.useMockData;
  }
  
  public shouldSkipJWTCheck(): boolean {
    return this.useMockData || this.isActive;
  }
  
  public shouldFetchUserIP(): boolean {
    return !this.useMockData;
  }

  // Novo método para configurar o uso de JWT em desenvolvimento
  public setUseJwtInDev(value: boolean): void {
    if (this.isActive) {
      this.useJwtInDev = value;
      this.useMockData = !value;
      console.log(`🔧 MODO DE DESENVOLVIMENTO: ${value ? 'Usando JWT real' : 'Usando dados mockados'}`);
      
      // Se estiver usando JWT em dev, ainda configurar hotkeys de desenvolvimento
      if (value) {
        setupDevHotkeys();
      }
    }
  }

  // Método para verificar se deve usar JWT em vez de mocks
  public shouldUseJwtInDev(): boolean {
    return this.useJwtInDev;
  }
  
  // Método para logs de desenvolvimento
  public devLog(message: string, ...data: unknown[]): void {
    if (this.isActive) {
      // Em ambiente de desenvolvimento, sempre mostrar os logs
      console.log(`🔧 DEV: ${message}`, ...data);
    }
  }
}

// Exportar uma instância única para uso em toda a aplicação
export const devEnv = DevEnvironment.getInstance();