import { FileTree, Local, Project, File, Discipline } from '../types/fileTree';

export const getFileTreeUtils = (fileTree: FileTree) => {
    // Função para encontrar o local de um arquivo pelo ID
    const findLocalByFileId = (fileId: string): Local | null => {
        const numericFileId = Number(fileId);
        for (const local of fileTree.locals) {
            for (const project of local.projects) {
                // Verificar nos arquivos diretamente no projeto
                if (project.files.some(file => file.id === numericFileId)) {
                    return local;
                }
                
                // Verificar nos arquivos dentro de disciplinas
                if (project.disciplines) {
                    for (const discipline of project.disciplines) {
                        if (discipline.files.some(file => file.id === numericFileId)) {
                            return local;
                        }
                    }
                }
            }
        }
        return null;
    };

    // Encontra um local pelo ID
    const findLocalById = (localId: string): Local | undefined => {
        return fileTree.locals.find(local => local.id.toString() === localId);
    };

    // Encontra um projeto pelo ID
    const findProjectById = (projectId: string): Project | null => {
        for (const local of fileTree.locals) {
            for (const project of local.projects) {
                if (project.id.toString() === projectId) {
                    return project;
                }
            }
        }
        return null;
    };

    // Encontra um projeto pelo ID do arquivo
    const findProjectByFileId = (fileId: string): Project | null => {
        const numericFileId = Number(fileId);
        for (const local of fileTree.locals) {
            for (const project of local.projects) {
                // Verificar nos arquivos diretamente no projeto
                if (project.files.some(file => file.id === numericFileId)) {
                    return project;
                }
                
                // Verificar nos arquivos dentro de disciplinas
                if (project.disciplines) {
                    for (const discipline of project.disciplines) {
                        if (discipline.files.some(file => file.id === numericFileId)) {
                            return project;
                        }
                    }
                }
            }
        }
        return null;
    };

    // Novo método para encontrar a disciplina de um arquivo
    const findDisciplineByFileId = (fileId: string): Discipline | null => {
        const numericFileId = Number(fileId);
        for (const local of fileTree.locals) {
            for (const project of local.projects) {
                if (project.disciplines) {
                    for (const discipline of project.disciplines) {
                        if (discipline.files.some(file => file.id === numericFileId)) {
                            return discipline;
                        }
                    }
                }
            }
        }
        return null;
    };

    // Obtém todos os locais ordenados alfabeticamente
    const getSortedLocals = (): Local[] => {
        return [...fileTree.locals].sort((a, b) => a.name.localeCompare(b.name));
    };

    // Obtém todos os projetos para um local específico, ordenados alfabeticamente
    const getSortedProjectsForLocal = (localId: string): Project[] => {
        const local = findLocalById(localId);
        if (!local) return [];
        
        return [...local.projects].sort((a, b) => a.name.localeCompare(b.name));
    };

    // Obtém todos os arquivos para um projeto específico, ordenados alfabeticamente
    const getSortedFilesForProject = (projectId: string): File[] => {
        const project = findProjectById(projectId);
        if (!project) return [];
        
        return [...project.files].sort((a, b) => a.name.localeCompare(b.name));
    };

    // Obtém todos os arquivos para um projeto específico que não estão em disciplinas
    const getNonDisciplineFilesForProject = (projectId: string): File[] => {
        const project = findProjectById(projectId);
        if (!project) return [];
        
        return project.files.filter(file => !file.discipline_id);
    };

    // Obtém arquivos de um projeto específico que não pertencem a disciplinas, ordenados alfabeticamente
    const getSortedNonDisciplineFilesForProject = (projectId: string): File[] => {
        return getNonDisciplineFilesForProject(projectId)
            .sort((a, b) => a.name.localeCompare(b.name));
    };

    return {
        findLocalByFileId,
        findLocalById,
        findProjectById,
        findProjectByFileId,
        findDisciplineByFileId,
        getSortedLocals,
        getSortedProjectsForLocal,
        getSortedFilesForProject,
        getNonDisciplineFilesForProject,
        getSortedNonDisciplineFilesForProject
    };
};