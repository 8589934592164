import * as BUI from '@thatopen/ui';
import * as OBC from '@thatopen/components';
import * as UTILS from '../../utils/utils';
import { ProjectTreeUI } from '../ProjectsUI/ProjectTreeUI';
import { ModelLoader } from '../../models/modelLoader';
import { FileTree } from '../../types/fileTree';

export const Projects = (
    components: OBC.Components,
    world: OBC.World,
    fileTree: FileTree,
    worldGrid?: OBC.SimpleGrid) => {
    // Set up fragments manager
    const fragments = components.get(OBC.FragmentsManager);

    // Set up IFC loader
    const fragmentIfcLoader = components.get(OBC.IfcLoader);
    fragmentIfcLoader.setup();

    // Initialize model loader
    const modelLoader = new ModelLoader(fragments, fragmentIfcLoader, world, worldGrid);

    // Initialize project tree UI
    const projectTreeUI = new ProjectTreeUI(fileTree, modelLoader);

    return BUI.Component.create<BUI.Panel>(() => {
        return BUI.html`
            <bim-panel>
                <bim-panel-section fixed label="${UTILS.capitalize(fileTree.name)}" icon="uiw:global">
                    <bim-label style="white-space: normal;">Abaixo estão os projetos para serem carregados no visualizador 3D. Marque a caixa para carregar e desmarque para descarregar o modelo.</bim-label>
                </bim-panel-section>
                ${projectTreeUI.generateLocalsHTML()}
            </bim-panel>
        `;
    });
};

export default Projects;