import { mockUserData, mockFetchFileById, getMockFileTree } from '../../mocks';
import { devEnv } from '../config/devConfig';
import { refreshAvailableModels } from '../utils/devUtils';
import { UserData } from '../types/userData';
import { getFileFromCache, saveFileToCache } from '../models/modelCache';

export async function verifyJWT(token: string) {
  // Em modo de desenvolvimento, podemos retornar dados mock
  // Exceto se estiver configurado para usar JWT em desenvolvimento
  if (devEnv.useMockData && !devEnv.shouldUseJwtInDev()) {
    console.log('DEV MODE: Usando dados de usuário mockados');
    saveUserDataToLocalStorage(mockUserData);
    return mockUserData;
  }

  if (token) {localStorage.setItem('jwtToken', token);}
  
  // Log em modo de desenvolvimento com JWT
  if (devEnv.isActive && devEnv.shouldUseJwtInDev()) {
    devEnv.devLog('Verificando JWT real em modo de desenvolvimento');
  }

  try {
    const response = await fetch('/api/verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token })
    });

    const userdata = await response.json();

    if (userdata.userClient) {
      // Log em modo de desenvolvimento com JWT
      if (devEnv.isActive && devEnv.shouldUseJwtInDev()) {
        devEnv.devLog('JWT validado com sucesso. Dados do usuário:', userdata);
      }

      saveUserDataToLocalStorage(userdata);
      
      // return user data
      return {
        userDisplayName: userdata.userDisplayName,
        userUid: userdata.userUid,
        userEmailDomain: userdata.userEmailDomain,
        userEmail: userdata.userEmail,
        userClient: userdata.userClient
      };
    } else {
      // Log em modo de desenvolvimento com JWT
      if (devEnv.isActive && devEnv.shouldUseJwtInDev()) {
        devEnv.devLog('Erro na validação do JWT:', userdata.error);
      }
      
      // return error
      return { error: userdata.error };
    }
  } catch (error) {
    console.error('Error verifying JWT:', error);
    // return error
    return { error: 'jwt.error' };
  }
}

function saveUserDataToLocalStorage(userData: UserData) {
  if (!userData) return;
  
  // Salva o token JWT também, se fornecido
  if (userData.jwtToken) localStorage.setItem('jwtToken', userData.jwtToken);
  
  if (userData.userDisplayName) localStorage.setItem('userDisplayName', userData.userDisplayName);
  if (userData.userUid) localStorage.setItem('userUid', userData.userUid);
  if (userData.userEmailDomain) localStorage.setItem('userEmailDomain', userData.userEmailDomain);
  if (userData.userEmail) localStorage.setItem('userEmail', userData.userEmail);
  if (userData.userClient) localStorage.setItem('userClient', userData.userClient);
}

export async function getFileTree(userClient: string, token?: string) {
  // Em modo de desenvolvimento, podemos retornar dados mock
  // Exceto se estiver configurado para usar JWT em desenvolvimento
  if (devEnv.useMockData && !devEnv.shouldUseJwtInDev()) {
    console.log('DEV MODE: Usando árvore de arquivos mockada');
    
    // Usar a função que garante que os dados estão carregados
    return await getMockFileTree();
  }

  // Log em modo de desenvolvimento com JWT
  if (devEnv.isActive && devEnv.shouldUseJwtInDev()) {
    devEnv.devLog(`Buscando árvore de arquivos real para cliente: ${userClient}`);
  }

  const jwtToken = token || localStorage.getItem('jwtToken') || '';

  if (!jwtToken) {
    console.error('No JWT token provided ou found in localStorage');
    return { error: 'auth.error' };
  }

  try {
    const response = await fetch(`/api/client/${userClient}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwtToken}`
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      return { error: errorData.message || 'filetree.error' };
    }

    const fileTree = await response.json();
    
    // Log em modo de desenvolvimento com JWT
    if (devEnv.isActive && devEnv.shouldUseJwtInDev()) {
      devEnv.devLog('Árvore de arquivos recebida:', fileTree);
    } else {
      console.log('User client:', userClient);
      console.log('File tree:', fileTree);
    }
    
    return fileTree;
  } catch (error) {
    console.error('Error getting file tree:', error);
    return { error: 'filetree.error' };
  }
}

// Function to fetch file by ID
export async function fetchFileById(fileId: string, token?: string): Promise<ArrayBuffer> {
  // Primeiro verificar se o arquivo está no cache
  const cachedFile = await getFileFromCache(fileId);
  if (cachedFile) {
    console.log(`Arquivo ${fileId} carregado do cache local`);
    return cachedFile.buffer;
  }

  // Em modo de desenvolvimento, podemos retornar dados mock
  // Exceto se estiver configurado para usar JWT em desenvolvimento
  if (devEnv.useMockData && !devEnv.shouldUseJwtInDev()) {
    console.log('DEV MODE: Usando arquivo IFC mockado');
    const mockBuffer = await mockFetchFileById(fileId);
    // Salvar o arquivo mockado no cache
    await saveFileToCache(fileId, new Uint8Array(mockBuffer));
    return mockBuffer;
  }

  // Log em modo de desenvolvimento com JWT
  if (devEnv.isActive && devEnv.shouldUseJwtInDev()) {
    devEnv.devLog(`Buscando arquivo real com ID: ${fileId}`);
  }

  const jwtToken = token || localStorage.getItem('jwtToken') || '';

  if (!jwtToken) {
    console.error('No JWT token provided or found in localStorage');
    throw new Error('auth.error');
  }

  try {
    const response = await fetch(`/api/file/${fileId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${jwtToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch file: ${response.status}`);
    }

    const data = await response.arrayBuffer();
    
    // Log em modo de desenvolvimento com JWT
    if (devEnv.isActive && devEnv.shouldUseJwtInDev()) {
      devEnv.devLog(`Arquivo ${fileId} carregado com sucesso. Tamanho: ${(data.byteLength / 1024 / 1024).toFixed(2)} MB`);
    }
    
    // Salvar no cache para uso futuro
    await saveFileToCache(fileId, new Uint8Array(data));
    
    return data;
  } catch (error) {
    console.error(`Error fetching file with ID ${fileId}:`, error);
    throw error;
  }
}

// function to update files /rescan
export async function updateFiles() {
  // Verificar se está em modo desenvolvimento
  if (devEnv.useMockData && !devEnv.shouldUseJwtInDev()) {
    console.log('DEV MODE: Atualizando modelos disponíveis');
    await refreshAvailableModels();
    // Atualiza a página no navegador
    window.location.reload();
    return { success: true };
  }

  const jwtToken = localStorage.getItem('jwtToken') || '';

  const userDomain = localStorage.getItem('userEmailDomain') || '';

  if (userDomain !== 'technoproj.com.br') {
    console.error('Usuário sem permissão para executar rescan');
    console.error('Email:', userDomain);
    return { error: 'permission.denied', status: 403 };
  }

  if (!jwtToken) {
    console.error('No JWT token provided or found in localStorage');
    return { error: 'auth.error' };
  }
  try {
    // Log para depuração
    if (devEnv.isActive && devEnv.shouldUseJwtInDev()) {
      devEnv.devLog(`Enviando solicitação de rescan com token: ${jwtToken}...`);
    }
    
    const response = await fetch('/api/rescan', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwtToken}`
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Erro ao atualizar arquivos:', response.status, errorData);
      return { error: errorData.message || 'update.error', status: response.status };
    }

    console.log('Update:', response);

    // Atualiza a página no navegador
    window.location.reload();

    return { success: true };
  } catch (error) {
    console.error('Error updating files:', error);
    return { error: 'update.error' };
  }
}

export const userdata = {
  userClient: 'technoproj', // ou qualquer outra lógica para determinar o cliente do usuário
  // outros campos conforme necessário
};