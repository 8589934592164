// modelLoader.ts
import * as OBC from '@thatopen/components';
import { fetchFileById } from '../api/apiCalls';
import { getQueueProcessor } from '../utils/queueProcessor';
import { FragmentsGroup } from '@thatopen/fragments';
import { Project, Discipline, File } from '../types/fileTree';
import { devEnv } from '../config/devConfig';

export class ModelLoader {
  private fragments: OBC.FragmentsManager;
  private ifcLoader: OBC.IfcLoader;
  private world: OBC.World;
  private loadedModels: Map<string, FragmentsGroup> = new Map();
  private queueProcessor: ReturnType<typeof getQueueProcessor>;
  private worldGrid?: OBC.SimpleGrid; // Add a reference to the grid;

  constructor(
    fragments: OBC.FragmentsManager,
    ifcLoader: OBC.IfcLoader,
    world: OBC.World,
    worldGrid?: OBC.SimpleGrid,
  ) {
    this.fragments = fragments;
    this.ifcLoader = ifcLoader;
    this.world = world;
    this.worldGrid = worldGrid;
    this.queueProcessor = getQueueProcessor(
      this.loadIfcById.bind(this),
      this.unloadIfcById.bind(this)
    );
  }

  public queueFileOperation(
    fileId: string,
    fileName: string,
    checkbox: HTMLElement,
    isLoading: boolean
  ) {
    this.queueProcessor.addToQueue({ fileId, fileName, checkbox, isLoading });
  }

  public async loadIfcById(
    fileId: string,
    fileName: string
  ): Promise<FragmentsGroup> {
    console.log(`Iniciando carregamento do arquivo: ${fileName}`);

    try {
      // Em modo de desenvolvimento, não precisamos do token JWT
      let buffer: Uint8Array;

      if (devEnv.isActive) {
        // No modo dev, o fetchFileById já trata a carga dos arquivos mockados
        buffer = new Uint8Array(await fetchFileById(fileId));
      } else {
        // Modo de produção - usa token JWT normal
        const token = localStorage.getItem('jwtToken') || '';
        if (!token) throw new Error('No JWT token found');
        buffer = new Uint8Array(await fetchFileById(fileId, token));
      }

      const model = (await this.ifcLoader.load(buffer)) as FragmentsGroup;
      model.name = fileName;

      // Adiciona o modelo carregado à cena
      this.world.scene.three.add(model);

      // Hide the grid when a model is loaded
      if (this.worldGrid) {
        this.worldGrid.visible = false;
      }

      // Armazena o modelo carregado para referência futura
      this.loadedModels.set(fileId, model);

      console.log(`Arquivo carregado com sucesso: ${fileName}`);
      return model;
    } catch (error) {
      console.error(`Erro ao carregar o modelo IFC: ${fileName}`, error);
      throw error;
    } finally {
      console.log(`Carregamento concluído: ${fileName}`);
    }
  }

  // Método para carregar arquivos específicos de um projeto (em vez de todos os arquivos)
  public async loadProjectFiles(
    files: File[],
    fileCheckboxes: Map<string, HTMLElement>,
    onLoadStart?: (fileId: string, checkbox: HTMLElement) => void,
    onLoadComplete?: (
      fileId: string,
      checkbox: HTMLElement,
      success: boolean
    ) => void
  ): Promise<void> {
    const loadPromises: Promise<void>[] = [];

    for (const file of files) {
      const fileId = file.id.toString();
      const fileCheckbox = fileCheckboxes.get(fileId);

      if (fileCheckbox) {
        // Notify that loading has started
        if (onLoadStart) onLoadStart(fileId, fileCheckbox);

        // Create a promise to track the loading of this file
        const loadPromise = new Promise<void>((resolve) => {
          this.queueFileOperation(fileId, file.name, fileCheckbox, true);

          // Poll to check if the file has loaded
          const checkInterval = setInterval(() => {
            if (this.loadedModels.has(fileId)) {
              clearInterval(checkInterval);
              if (onLoadComplete) onLoadComplete(fileId, fileCheckbox, true);
              resolve();
            }
          }, 500);

          // Timeout after 30 seconds to prevent infinite waiting
          setTimeout(() => {
            clearInterval(checkInterval);
            if (!this.loadedModels.has(fileId)) {
              if (onLoadComplete) onLoadComplete(fileId, fileCheckbox, false);
            }
            resolve();
          }, 30000);
        });

        loadPromises.push(loadPromise);
      }
    }

    // Wait for all files to finish loading
    await Promise.all(loadPromises);
  }

  // Atualizando o método existente para usar o novo método loadProjectFiles
  public async loadAllProjectFiles(
    project: Project,
    fileCheckboxes: Map<string, HTMLElement>,
    onLoadStart?: (fileId: string, checkbox: HTMLElement) => void,
    onLoadComplete?: (
      fileId: string,
      checkbox: HTMLElement,
      success: boolean
    ) => void
  ): Promise<void> {
    // Filter files that don’t belong to disciplines
    const nonDisciplineFiles = project.files.filter(
      (file) => !file.discipline_id
    );

    // Use the updated loadProjectFiles method
    await this.loadProjectFiles(
      nonDisciplineFiles,
      fileCheckboxes,
      onLoadStart,
      onLoadComplete
    );
  }

  public async loadAllDisciplineFiles(
    discipline: Discipline,
    fileCheckboxes: Map<string, HTMLElement>,
    onLoadStart: (fileId: string, checkbox: HTMLElement) => void = () => { },
    onLoadComplete: (
      fileId: string,
      checkbox: HTMLElement,
      success: boolean
    ) => void = () => { }
  ): Promise<void> {
    console.log(`Carregando todos os arquivos da disciplina ${discipline.name}`);

    const loadPromises: Promise<void>[] = [];

    for (const file of discipline.files) {
      const fileId = file.id.toString();
      const checkbox = fileCheckboxes.get(fileId);

      if (checkbox) {
        // Notify that loading has started
        onLoadStart(fileId, checkbox);

        // Create a promise to track the loading of this file
        const loadPromise = new Promise<void>((resolve) => {
          this.queueFileOperation(fileId, file.name, checkbox, true);

          // Poll to check if the file has loaded
          const checkInterval = setInterval(() => {
            if (this.loadedModels.has(fileId)) {
              clearInterval(checkInterval);
              onLoadComplete(fileId, checkbox, true);
              resolve();
            }
          }, 500);

          // Timeout after 30 seconds to prevent infinite waiting
          setTimeout(() => {
            clearInterval(checkInterval);
            if (!this.loadedModels.has(fileId)) {
              onLoadComplete(fileId, checkbox, false);
            }
            resolve();
          }, 30000);
        });

        loadPromises.push(loadPromise);
      }
    }

    // Wait for all files to finish loading
    await Promise.all(loadPromises);
  }

  public unloadIfcById(fileId: string, fileName: string): boolean {
    try {
      const model = this.loadedModels.get(fileId);

      if (model) {
        // Remove o modelo da cena
        this.world.scene.three.remove(model);

        // Tenta liberar os recursos do modelo específico
        try {
          // Utilize o método dispose group passando o group a ser removido
          this.fragments.disposeGroup(model);
        } catch (disposeError) {
          console.warn(
            `Não foi possível descarregar o fragmento para o modelo ${fileName}`,
            disposeError
          );
        }

        // Remove da lista de modelos carregados
        this.loadedModels.delete(fileId);

        console.log(`Arquivo descarregado com sucesso: ${fileName}`);
        return true;
      } else {
        console.warn(`Modelo ${fileName} não encontrado para descarregar`);
        return false;
      }
    } catch (error) {
      console.error(`Erro ao descarregar o modelo IFC: ${fileName}`, error);
      return false;
    }
  }

  public unloadAllModels(fileCheckboxes: Map<string, HTMLElement>): void {
    for (const [loadedFileId, model] of this.loadedModels.entries()) {
      this.world.scene.three.remove(model);
      try {
        this.fragments.disposeGroup(model);
      } catch (disposeError) {
        console.warn(
          'Erro ao descarregar modelo durante a troca de local',
          disposeError
        );
      }

      // Desmarcar o checkbox associado ao modelo descarregado
      const checkbox = fileCheckboxes.get(loadedFileId);
      if (checkbox) {
        checkbox.removeAttribute('checked');
      }
    }

    // Cleaning memory
    // para de funcionar a tabela de arquivos carregados
    // this.fragments.dispose();

    // Limpar o mapa de modelos carregados
    this.loadedModels.clear();

    // Re-enable the grid when all models are unloaded
    if (this.worldGrid) {
      this.worldGrid.visible = true;
    }
  }

  public getLoadedModels(): Map<string, FragmentsGroup> {
    return this.loadedModels;
  }
}
