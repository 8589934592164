/**
 * Funções utilitárias para o ambiente de desenvolvimento
 */

/**
 * Carrega um arquivo IFC local para teste
 * @param path Caminho relativo ao arquivo no diretório public (ex: '/models/example.ifc')
 */
export async function loadLocalIFC(path: string): Promise<ArrayBuffer> {
  try {
    console.log(`Carregando arquivo IFC local: ${path}`);
    const response = await fetch(path);
    
    if (!response.ok) {
      throw new Error(`Falha ao carregar arquivo: ${response.status} ${response.statusText}`);
    }
    
    return await response.arrayBuffer();
  } catch (error) {
    console.error('Erro ao carregar arquivo IFC local:', error);
    throw error;
  }
}

/**
 * Recarrega a lista de modelos disponíveis
 * Útil quando novos arquivos são adicionados durante o desenvolvimento
 */
export async function refreshAvailableModels(): Promise<void> {
  if (import.meta.env.DEV) {
    try {
      const response = await fetch('/api/dev-models');
      if (!response.ok) {
        throw new Error(`Erro ao atualizar lista de modelos: ${response.statusText}`);
      }
      console.log('Lista de modelos atualizada com sucesso');
      // Recarregar a página para aplicar as mudanças
      window.location.reload();
    } catch (error) {
      console.error('Falha ao atualizar a lista de modelos:', error);
    }
  }
}

/**
 * Configura os eventos de teclado para desenvolvimento
 */
export function setupDevHotkeys(): void {
  if (import.meta.env.DEV) {
    window.addEventListener('keydown', (event) => {
      // Ctrl+R ou Command+R para recarregar a página
      if ((event.ctrlKey || event.metaKey) && event.key === 'r') {
        console.log('Recarregando a página manualmente...');
        window.location.reload();
      }
      
      // Ctrl+M ou Command+M para recarregar a lista de modelos
      if ((event.ctrlKey || event.metaKey) && event.key === 'm') {
        console.log('Atualizando lista de modelos...');
        refreshAvailableModels();
        event.preventDefault(); // Previne o comportamento padrão do navegador
      }
    });
    
    console.log('🔧 DEV: Atalhos de desenvolvimento ativados:');
    console.log('  - Ctrl+R: Recarregar a página');
    console.log('  - Ctrl+M: Atualizar lista de modelos IFC');
  }
}
