// main.ts
import * as THREE from 'three';
import * as OBC from '@thatopen/components';
import * as OBF from '@thatopen/components-front';
import * as BUI from '@thatopen/ui';
import projectInformation from './components/Panels/ProjectInformation';
import elementData from './components/Panels/Selection';
import settings from './components/Panels/Settings';
import measure from './components/Toolbars/Sections/Measure';
import help from './components/Panels/Help';
import camera from './components/Toolbars/Sections/Camera';
import selection from './components/Toolbars/Sections/Selection';
import projects from './components/Panels/Projects';
import { AppManager } from './bim-components';
import { verifyJWT } from './api/apiCalls'; // Import function to verify JWT token
import { getFileTree } from './api/apiCalls'; // Import function to get file tree
import { devEnv } from './config/devConfig'; // Import development environment configuration
import { initCache } from './models/modelCache';

// Verificação de domínio - pular em modo dev com mock
if (!devEnv.shouldSkipDomainCheck()) {
  // Defina os domínios esperados
  const dominiosEsperados = ['https://nuvem.technoproj.com.br', 'https://technoproj.com.br', 'https://bimdev.technoproj.com.br', 'https://bimprod.technoproj.com.br'];

  // Obtenha o domínio atual
  const dominioAtual = document.location.origin;

  console.log('Domínio atual:', dominioAtual);

  // Verifique se o domínio atual está na lista de domínios esperados
  if (dominiosEsperados.some(dominio => dominioAtual.startsWith(dominio))) {
    console.log('O domínio é correto.');
    // Prossiga com a lógica do aplicativo

  } else {
    console.log('Acesso negado. O domínio não é o esperado.');
    // Redirecione o usuário ou mostre uma mensagem de erro
  }
}

// Extract JWT token from URL parameters
const urlParams = new URLSearchParams(window.location.search);
const jwt = urlParams.get('jwt');

// Se um JWT foi fornecido e estamos em modo de desenvolvimento,
// configure o sistema para usar este JWT em vez de dados mockados
if (jwt && devEnv.isActive) {
  devEnv.setUseJwtInDev(true);
  console.log('Token JWT detectado em modo de desenvolvimento, usando API real');
}

// Esconder o token JWT da URL
//window.history.replaceState({}, document.title, "/");

// Função para obter o IP do usuário
async function text(url: string) {
  const res = await fetch(url);
  return res.text();
}

// Em desenvolvimento, não precisamos obter o IP
if (devEnv.shouldFetchUserIP()) {
  text('https://www.cloudflare.com/cdn-cgi/trace').then(data => {
    const ipRegex = /[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/;
    const ip = data.match(ipRegex);
    if (ip) {
      console.log(ip[0]);
    }
  });
}

// Get the main app element
const app = document.getElementById('app') as BUI.Grid;

// Verificação JWT - pular em modo dev com mock
if (jwt || devEnv.shouldSkipJWTCheck()) {
  // Se jwt existe, salvar no localStorage
  if (jwt) {
    localStorage.setItem('jwtToken', jwt);
  }
  // Get JWT token from localStorage
  const jwtToken = localStorage.getItem('jwtToken') || '';

  // If JWT token exists, verify its validity
  verifyJWT(jwtToken).then(async (userdata) => {

    // Se estamos em modo de desenvolvimento com mock, ignoramos verificação de erro
    if (userdata.error && !devEnv.shouldSkipJWTCheck()) {
      console.log('Error:', userdata.error);
      window.location.href = 'https://nuvem.technoproj.com.br';
    } else {
      // If JWT token is valid, log the user data
      console.log('User Data:', userdata);

      // Get file tree
      const fileTree = await getFileTree(userdata.userClient, jwtToken);

      // Initialize the BUI manager
      BUI.Manager.init(); // Initialize the BUI manager to handle UI components

      // Initialize the BIM (Building Information Modeling) app
      // Create a components instance
      const components = new OBC.Components();
      // Get the Worlds component from components
      const worlds = components.get(OBC.Worlds);

      // Create a new world with a simple scene, orthographic perspective camera, and postproduction renderer
      const world = worlds.create<OBC.SimpleScene, OBC.OrthoPerspectiveCamera, OBF.PostproductionRenderer>();
      world.name = 'Main';

      // Set up the scene in the world
      world.scene = new OBC.SimpleScene(components);
      world.scene.setup();
      world.scene.three.background = null;

      // Create a viewport using BUI Component
      const viewport = BUI.Component.create<BUI.Viewport>(() => {
        return BUI.html`
          <bim-viewport>
            <bim-grid floating></bim-grid>
          </bim-viewport>
        `;
      });

      const buttonHideLeftPanel = BUI.Component.create<BUI.PanelSection>(() => {
        return BUI.html`
          <bim-panel-section hidebutton class="panel-section">
            <button class="hide-panel-button" title="Ocultar/Mostrar painel"
              @click=${hideLeftPanel}>
              <bim-icon hideLeftPanel icon="octicon:chevron-left-16" style="color: var(--bim-ui_bg-contrast-80);"></bim-icon>
            </button>
          </bim-panel-section>
        `;
      });


      // Set the renderer of the world using the postproduction renderer
      world.renderer = new OBF.PostproductionRenderer(components, viewport);
      const { postproduction } = world.renderer;

      // Set up the camera for the world
      world.camera = new OBC.OrthoPerspectiveCamera(components);

      // Create and customize a grid for the world
      const worldGrid = components.get(OBC.Grids).create(world);
      worldGrid.material.uniforms.uColor.value = new THREE.Color(0x424242);
      worldGrid.material.uniforms.uSize1.value = 2;
      worldGrid.material.uniforms.uSize2.value = 8;

      // Function to resize the world
      const resizeWorld = () => {
        world.renderer?.resize();
        world.camera.updateAspect();
      };

      // Add event listener for resizing the viewport
      viewport.addEventListener('resize', resizeWorld);

      // Initialize components
      components.init();

      // Enable postproduction effects
      postproduction.enabled = true;
      postproduction.customEffects.excludedMeshes.push(worldGrid.three);
      postproduction.setPasses({ custom: true, ao: true, gamma: true });
      postproduction.customEffects.lineColor = 0x17191c;

      // Get the AppManager component
      const appManager = components.get(AppManager);
      const viewportGrid = viewport.querySelector<BUI.Grid>('bim-grid[floating]')!;
      appManager.grids.set('viewport', viewportGrid);


      // Get various components needed for loading and rendering models
      const fragments = components.get(OBC.FragmentsManager);
      const indexer = components.get(OBC.IfcRelationsIndexer);
      const classifier = components.get(OBC.Classifier);
      classifier.list.CustomSelections = {};

      // Set up IfcStreamer for loading tiles
      const tilesLoader = components.get(OBF.IfcStreamer);
      tilesLoader.world = world;
      tilesLoader.culler.threshold = 10;
      tilesLoader.culler.maxHiddenTime = 1000;
      tilesLoader.culler.maxLostTime = 40000;

      // Set up highlighter
      const highlighter = components.get(OBF.Highlighter);
      highlighter.setup({ world });
      highlighter.zoomToSelection = true;

      // Create and set up culler for optimizing rendering performance
      const culler = components.get(OBC.Cullers).create(world);
      culler.threshold = 5;

      // Set camera controls behavior
      world.camera.controls.restThreshold = 0.25;
      world.camera.controls.addEventListener('rest', () => {
        culler.needsUpdate = true;
        tilesLoader.culler.needsUpdate = true;
      });

      // Inicializar o cache de modelos IFC
      initCache()
        .then(() => console.log('Cache de modelos IFC inicializado com sucesso'))
        .catch(error => console.error('Erro ao inicializar o cache de modelos IFC:', error));


      // Event listener for handling loaded fragments
      fragments.onFragmentsLoaded.add(async (model) => {
        if (model.hasProperties) {
          await indexer.process(model);
          classifier.byEntity(model);
        }

        if (!model.isStreamed) {
          for (const fragment of model.items) {
            world.meshes.add(fragment.mesh);
            // culler.add(fragment.mesh);
          }
        }

        world.scene.three.add(model);

        if (!model.isStreamed) {
          setTimeout(async () => {
            world.camera.fit(world.meshes, 0.8);
          }, 50);
        }
      });

      fragments.onFragmentsDisposed.add(({ fragmentIDs }) => {
        for (const fragmentID of fragmentIDs) {
          const mesh = [...world.meshes].find((mesh) => mesh.uuid === fragmentID);
          if (mesh) {
            world.meshes.delete(mesh);
          }
        }
      });


      // Initialize panels and toolbar components
      const projectFiles = projects(components, world, fileTree, worldGrid);
      const projectInformationPanel = projectInformation(components);
      const elementDataPanel = elementData(components);


      // Create left panel with tabs for project information, settings, and help
      const leftPanel = BUI.Component.create(() => {
        return BUI.html`
        <bim-panel class="bim-left-painel">
          <bim-tabs switchers-full>
            <bim-tab name="clientFiles" label="Projetos" icon="pajamas:project">
              ${projectFiles}
            </bim-tab>
            <bim-tab name="project" label="Modelos" icon="octicon:info-16">
              ${projectInformationPanel}
            </bim-tab>
            <bim-tab name="settings" label="Opções" icon="oui:gear">
              ${settings(components)}
            </bim-tab>
            <bim-tab name="help" label="Ajuda" icon="octicon:question-16">
              ${help}
            </bim-tab>            
          </bim-tabs>
        </bim-panel>  
        `;
      });


      const toolbar = BUI.Component.create(() => {
        return BUI.html`
          <bim-toolbar>
            ${camera(world)}
            ${selection(components, world)}
            ${measure(components, app, world)}
          </bim-toolbar>
          `;
      });


      // Define layouts for main app
      app.layouts = {
        main: {
          template: `
            "leftPanel viewport" 1fr 
            / 24rem 1fr 
          `,
          elements: {
            leftPanel,
            viewport,
          },
        },
        second: {
          template: `
            "viewport" 1fr 
            /1fr
          `,
          elements: {
            viewport,
          }
        }
      };

      // Set main layout for the app
      app.layout = 'main';

      // Define layouts for viewport grid
      viewportGrid.layouts = {
        main: {
          template: `
            "buttonHideLeftPanel" 2.25rem
            "empty" 1fr
            "toolbar" auto
            / 1fr 
          `,
          elements: { buttonHideLeftPanel, toolbar },
        },
        second: {
          template: `
            "buttonHideLeftPanel elementDataPanel" 1fr
            "toolbar elementDataPanel" auto
            / 1fr 24rem
          `,
          elements: {
            buttonHideLeftPanel,
            toolbar,
            elementDataPanel
          },
        },
      };

      // Set layout for the viewport grid
      viewportGrid.layout = 'main';

    }
  });

} else {
  // If JWT token doesn't exist, redirect to home page
  window.location.href = 'https://nuvem.technoproj.com.br';
}

// Function to hide left panel
function hideLeftPanel() {
  const iconElement = document.querySelector('bim-icon[hideLeftPanel]');

  if (app.layout === 'main') {
    app.layout = 'second';
    if (iconElement) {
      iconElement.setAttribute('icon', 'octicon:chevron-right-16');
    }
  } else {
    app.layout = 'main';
    if (iconElement) {
      iconElement.setAttribute('icon', 'octicon:chevron-left-16');
    }
  }
}
