import { File } from '../../types/fileTree';

export class FileUI {
	private file: File;
	private fileCheckboxes: Map<string, HTMLElement>;
	private fileSpinners: Map<string, HTMLElement>;
	private onFileCheckboxChange: (fileId: string, fileName: string, checked: boolean) => void;

	constructor(
		file: File,
		fileCheckboxes: Map<string, HTMLElement>,
		fileSpinners: Map<string, HTMLElement>,
		onFileCheckboxChange: (fileId: string, fileName: string, checked: boolean) => void
	) {
		this.file = file;
		this.fileCheckboxes = fileCheckboxes;
		this.fileSpinners = fileSpinners;
		this.onFileCheckboxChange = onFileCheckboxChange;
	}

	public createElement(): HTMLElement {
		const fileDiv = document.createElement('div');
		fileDiv.classList.add('file-div');

		const fileLabel = document.createElement('bim-label');
		fileLabel.icon = 'mage:box-3d';
		fileLabel.textContent = this.file.name;

		const spinnerIcon = document.createElement('bim-icon');
		spinnerIcon.classList.add('spinner-icon');
		spinnerIcon.setAttribute('icon', 'svg-spinners:ring-resize');

		const fileId = this.file.id.toString();
		this.fileSpinners.set(fileId, spinnerIcon);

		const checkbox = document.createElement('bim-checkbox');
		checkbox.name = fileId;
		checkbox.title = 'Carregar arquivo';
		checkbox.addEventListener('change', () => this.onFileCheckboxChange(fileId, this.file.name, checkbox.checked));
		this.fileCheckboxes.set(fileId, checkbox);

		// Adicionar um observer para sincronizar o estado de disabled do checkbox com a exibição do spinner
		const observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.attributeName === 'style') {
					const isSpinnerVisible = spinnerIcon.style.display === 'block';
					// Simplesmente adiciona ou remove a classe conforme necessário
					checkbox.classList.toggle('disabled-checkbox', isSpinnerVisible);
				}
			});
		});

		observer.observe(spinnerIcon, { attributes: true });

		fileDiv.appendChild(fileLabel);
		fileDiv.appendChild(spinnerIcon);
		fileDiv.appendChild(checkbox);
		return fileDiv;
	}
}