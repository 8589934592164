import { QueueItem } from '../types/modelTypes';
import { FragmentsGroup } from '@thatopen/fragments';

export const getQueueProcessor = (
    loadFunction: (fileId: string, fileName: string) => Promise<FragmentsGroup>,
    unloadFunction: (fileId: string, fileName: string) => boolean
) => {
    // Queue to hold the loading tasks
    const loadQueue: QueueItem[] = [];
    let isProcessingQueue = false;

    // Process queue function
    const processQueue = async () => {
        if (isProcessingQueue) {
            console.log('Fila já está sendo processada.');
            return;
        }
        isProcessingQueue = true;
        console.log('Iniciando o processamento da fila...');

        while (loadQueue.length > 0) {
            const { fileId, fileName, checkbox, isLoading } = loadQueue.shift()!;
            console.log(`Processando arquivo da fila: ${fileName}`);

            // Set initial loading state
            checkbox.setAttribute('loading', 'true');
            checkbox.setAttribute('disabled', 'true');

            try {
                if (isLoading) {
                    // Carregar arquivo IFC
                    await loadFunction(fileId, fileName);
                    checkbox.setAttribute('checked', 'true');
                    console.log(`Arquivo carregado com sucesso: ${fileName}`);
                } else {
                    // Descarregar arquivo IFC
                    unloadFunction(fileId, fileName);
                    checkbox.removeAttribute('checked');
                    console.log(`Arquivo descarregado: ${fileName}`);
                }
            } catch (error) {
                console.error(`Erro ao processar o arquivo IFC: ${fileName}`, error);
                checkbox.removeAttribute('checked');
            } finally {
                checkbox.removeAttribute('loading');
                checkbox.removeAttribute('disabled');
            }
        }

        isProcessingQueue = false;
        console.log('Fila processada completamente.');
    };

    // Add to queue function
    const addToQueue = (item: QueueItem) => {
        loadQueue.push(item);
        console.log(`Arquivo adicionado à fila: ${item.fileName} para ${item.isLoading ? 'carregamento' : 'descarregamento'}`);
        processQueue();
    };

    return {
        addToQueue,
        processQueue,
        getQueue: () => [...loadQueue]
    };
};