/**
 * Mock de variáveis de ambiente para testes e desenvolvimento
 */
export const mockEnv = {
  DEV: true,
  VITE_USE_MOCK_DATA: 'true',
  MODE: 'test',
};

/**
 * Mock para import.meta.env usado em testes
 */
export const mockImportMeta = {
  env: mockEnv
};

export default mockEnv;
