import { FileTree, Local, Project, Discipline, File, AvailableModel } from './types';
import { loadLocalIFC } from '../src/utils/devUtils';
// Importar mockEnv para uso interno
import { mockEnv } from './env';

// Removendo interfaces personalizadas e usando os tipos existentes

/**
 * Dados mockados do usuário
 */
export const mockUserData = {
  userDisplayName: 'Dev User',
  userUid: 'dev123',
  userEmailDomain: 'dev.local',
  userEmail: 'dev@dev.local',
  userClient: 'technoproj'
};

// Uma referência mutável à árvore de arquivos
const fileTree: FileTree = {
  id: 1,
  name: 'Technoproj',
  locals: []
};

// Estado para armazenar os modelos disponíveis
let availableModels: AvailableModel[] = [];

// Flag para controlar se os dados já foram carregados
let isDataLoaded = false;

// Função para garantir que os dados sejam carregados apenas uma vez
async function ensureDataLoaded(): Promise<void> {
  if (isDataLoaded || typeof window === 'undefined' || process.env.NODE_ENV === 'test') {
    return;
  }

  try {
    const models = await fetchAvailableModels();
    availableModels = models;
    console.log('Modelos IFC disponíveis:', models);
    
    // Agrupa os arquivos por localização
    const locationMap = new Map<string, {
      models: typeof models,
      projects: Map<string, {
        models: typeof models,
        disciplines: Map<string, typeof models>
      }>
    }>();
    
    // Organiza os modelos na estrutura hierárquica
    models.forEach(model => {
      const locationName = model.location || 'Local Padrão';
      const projectName = model.project || 'Projeto Padrão';
      const disciplineName = model.discipline;
      
      // Inicializa a localização se não existir
      if (!locationMap.has(locationName)) {
        locationMap.set(locationName, {
          models: [],
          projects: new Map()
        });
      }
      
      const location = locationMap.get(locationName)!;
      location.models.push(model);
      
      // Inicializa o projeto se não existir
      if (!location.projects.has(projectName)) {
        location.projects.set(projectName, {
          models: [],
          disciplines: new Map()
        });
      }
      
      const project = location.projects.get(projectName)!;
      project.models.push(model);
      
      // Se tiver disciplina, adiciona no mapa de disciplinas
      if (disciplineName) {
        if (!project.disciplines.has(disciplineName)) {
          project.disciplines.set(disciplineName, []);
        }
        project.disciplines.get(disciplineName)!.push(model);
      }
    });
    
    // Constrói a estrutura da árvore de arquivos
    let localId = 1;
    let projectId = 1;
    let disciplineId = 1;
    let fileId = 1;
    
    // Limpa a estrutura atual
    fileTree.locals = [];
    
    // Para cada localização
    locationMap.forEach((location, locationName) => {
      const localNode: Local = {
        id: localId,
        client_id: 1,
        name: locationName,
        projects: []
      };
      
      fileTree.locals.push(localNode);
      
      // Para cada projeto na localização
      location.projects.forEach((project, projectName) => {
        const projectNode: Project = {
          id: projectId,
          local_id: localId,
          name: projectName,
          files: [],
          disciplines: []
        };
        
        localNode.projects.push(projectNode);
        
        // Para cada disciplina no projeto
        project.disciplines.forEach((disciplineFiles, disciplineName) => {
          const discipline: Discipline = {
            id: disciplineId,
            project_id: projectId,
            name: disciplineName,
            files: []
          };
          
          // Verifica se disciplines existe antes de fazer o push
          if (!projectNode.disciplines) {
            projectNode.disciplines = [];
          }
          projectNode.disciplines.push(discipline);
          
          // Adiciona arquivos à disciplina
          disciplineFiles.forEach(file => {
            const fileNode: File = {
              id: fileId,
              project_id: projectId,
              discipline_id: disciplineId,
              name: file.name,
              file_path: file.path
            };
            
            discipline.files.push(fileNode);
            projectNode.files.push(fileNode);
            fileId++;
          });
          
          disciplineId++;
        });
        
        // Adiciona arquivos sem disciplina diretamente ao projeto
        project.models.forEach(file => {
          if (!file.discipline) {
            const fileNode: File = {
              id: fileId,
              project_id: projectId,
              discipline_id: undefined, // Mudando de null para undefined
              name: file.name,
              file_path: file.path
            };
            
            projectNode.files.push(fileNode);
            fileId++;
          }
        });
        
        projectId++;
      });
      
      localId++;
    });
    
    // Remove locais sem projetos
    fileTree.locals = fileTree.locals.filter(local => local.projects.length > 0);
    
    console.log('Árvore de projetos configurada:', fileTree);
    isDataLoaded = true;
  } catch (error) {
    console.error('Erro ao carregar dados:', error);
  }
}

/**
 * Função para buscar dinamicamente a lista de modelos disponíveis
 */
async function fetchAvailableModels(): Promise<AvailableModel[]> {
  try {
    if (mockEnv.DEV) {
      const response = await fetch('/api/dev-models');
      if (!response.ok) {
        throw new Error(`Erro ao buscar lista de modelos: ${response.statusText}`);
      }
      const data = await response.json();
      return data.files;
    }
    return [];
  } catch (error) {
    console.error('Falha ao carregar a lista de modelos:', error);
    return [];
  }
}

/**
 * Função para obter o caminho de um arquivo pelo ID
 */
const getModelPathById = (fileId: string): string | null => {
  const model = availableModels.find(m => m.id === fileId);
  return model ? model.path : null;
};

/**
 * Mock para a resposta da função fetchFileById
 */
export const mockFetchFileById = async (fileId: string): Promise<ArrayBuffer> => {
  console.log(`Mock: fetchFileById chamado para ID ${fileId}`);
  
  // Verifica se temos um arquivo local para este ID
  const filePath = getModelPathById(fileId);
  
  if (filePath) {
    try {
      // Carrega o arquivo IFC localmente usando o método de desenvolvimento
      const buffer = await loadLocalIFC(filePath);
      return buffer;
    } catch (error) {
      console.error('Erro ao carregar arquivo IFC local:', error);
      throw new Error(`Falha ao carregar arquivo: ${error}`);
    }
  } else {
    throw new Error(`Arquivo não encontrado para o ID: ${fileId}`);
  }
};

// Inicializa o carregamento dos dados
if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'test') {
  ensureDataLoaded();
}

// Exporta uma função que retorna a árvore de arquivos atual e garante o carregamento dos dados
export async function getMockFileTree(): Promise<FileTree> {
  if (!isDataLoaded && typeof window !== 'undefined') {
    await ensureDataLoaded();
  }
  return fileTree;
}

// Mantém a exportação direta para compatibilidade com código existente
export const mockFileTree: FileTree = fileTree;
