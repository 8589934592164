// Inicializar o sistema de cache
const dbName = 'IfcModelsCache';
const storeName = 'models';
let db: IDBDatabase | null = null;

// Cache de modelos IFC
export const initCache = async (): Promise<void> => {
	return new Promise((resolve, reject) => {
		if (db) {
			resolve();
			return;
		}

		const request = indexedDB.open(dbName, 1);

		request.onupgradeneeded = (event) => {
			const db = (event.target as IDBOpenDBRequest).result;
			if (!db.objectStoreNames.contains(storeName)) {
				db.createObjectStore(storeName, { keyPath: 'fileId' });
			}
		};

		request.onsuccess = (event) => {
			db = (event.target as IDBOpenDBRequest).result;
			console.log('Cache de modelos IFC inicializado');
			resolve();
		};

		request.onerror = (event) => {
			console.error('Erro ao inicializar o cache de modelos IFC', event);
			reject(new Error('Falha ao inicializar o cache'));
		};
	});
};

    // Verifica se o arquivo está no cache
export const getFileFromCache = async (fileId: string): Promise<Uint8Array | null> => {
	if (!db) await initCache();

	return new Promise<Uint8Array | null>((resolve) => {
		const transaction = db!.transaction([storeName], 'readonly');
		const store = transaction.objectStore(storeName);
		const request = store.get(fileId);

		request.onsuccess = (event) => {
			const result = (event.target as IDBRequest).result;
			if (result) {
				console.log(`Modelo ${fileId} encontrado no cache`);
				resolve(result.buffer);
			} else {
				resolve(null);
			}
		};

		request.onerror = (event) => {
			console.error('Erro ao buscar no cache', event);
			resolve(null);
		};
	});
};

// Salva o arquivo no cache
export const saveFileToCache = async (fileId: string, buffer: Uint8Array): Promise<void> => {
	if (!db) await initCache();

	return new Promise((resolve) => {
		try {
			const transaction = db!.transaction([storeName], 'readwrite');
			const store = transaction.objectStore(storeName);
			const request = store.put({
				fileId,
				buffer,
				timestamp: Date.now()
			});

			request.onsuccess = () => {
				console.log(`Modelo ${fileId} salvo no cache`);
				resolve();
			};

			request.onerror = (event) => {
				console.error('Erro ao salvar no cache', event);
				resolve();
			};
		} catch (error) {
			console.error('Erro ao tentar salvar no cache', error);
			resolve();
		}
	});
};