import { Project, Discipline } from '../../types/fileTree';
import { DisciplineUI } from './DisciplineUI';
import { FileUI } from './FileUI';

export class ProjectUI {
	private project: Project;
	private fileCheckboxes: Map<string, HTMLElement>;
	private projectCheckboxes: Map<string, HTMLElement>;
	private disciplineCheckboxes: Map<string, HTMLElement>;
	private fileSpinners: Map<string, HTMLElement>;
	private projectSpinners: Map<string, HTMLElement>;
	private disciplineSpinners: Map<string, HTMLElement>;
	private onProjectCheckboxChange: (project: Project, checked: boolean) => void;
	private onDisciplineCheckboxChange: (discipline: Discipline, checked: boolean) => void;
	private onFileCheckboxChange: (fileId: string, fileName: string, checked: boolean) => void;
	private adjustContainerHeight: () => void;

	constructor(
		project: Project,
		fileCheckboxes: Map<string, HTMLElement>,
		projectCheckboxes: Map<string, HTMLElement>,
		disciplineCheckboxes: Map<string, HTMLElement>,
		fileSpinners: Map<string, HTMLElement>,
		projectSpinners: Map<string, HTMLElement>,
		disciplineSpinners: Map<string, HTMLElement>,
		onProjectCheckboxChange: (project: Project, checked: boolean) => void,
		onDisciplineCheckboxChange: (discipline: Discipline, checked: boolean) => void,
		onFileCheckboxChange: (fileId: string, fileName: string, checked: boolean) => void,
		adjustContainerHeight: () => void
	) {
		this.project = project;
		this.fileCheckboxes = fileCheckboxes;
		this.projectCheckboxes = projectCheckboxes;
		this.disciplineCheckboxes = disciplineCheckboxes;
		this.fileSpinners = fileSpinners;
		this.projectSpinners = projectSpinners;
		this.disciplineSpinners = disciplineSpinners;
		this.onProjectCheckboxChange = onProjectCheckboxChange;
		this.onDisciplineCheckboxChange = onDisciplineCheckboxChange;
		this.onFileCheckboxChange = onFileCheckboxChange;
		this.adjustContainerHeight = adjustContainerHeight;
	}

	public createElement(): HTMLElement {
		const projectContainer = document.createElement('div');
		projectContainer.classList.add('project-container');

		const projectHeader = document.createElement('div');
		projectHeader.classList.add('project-header');

		const projectLabel = document.createElement('bim-label');
		projectLabel.icon = 'ep:arrow-right-bold';
		projectLabel.classList.add('project-label');
		projectLabel.textContent = this.project.name;

		const spinnerIcon = document.createElement('bim-icon');
		spinnerIcon.setAttribute('icon', 'svg-spinners:ring-resize');
		spinnerIcon.classList.add('spinner-icon');

		const projectId = this.project.id.toString();
		this.projectSpinners.set(projectId, spinnerIcon);

		const projectCheckbox = document.createElement('bim-checkbox');
		projectCheckbox.name = `project_${projectId}`;
		projectCheckbox.addEventListener('change', () => this.onProjectCheckboxChange(this.project, projectCheckbox.checked));
		projectCheckbox.title = 'Carregar todos arquivos do projeto';
		this.projectCheckboxes.set(projectId, projectCheckbox);

		// Adicionar um observer para sincronizar o estado de disabled do checkbox com a exibição do spinner
		const observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.attributeName === 'style') {
					const isSpinnerVisible = spinnerIcon.style.display === 'block';
					// Simplesmente adiciona ou remove a classe conforme necessário
					projectCheckbox.classList.toggle('disabled-checkbox', isSpinnerVisible);
				}
			});
		});

		observer.observe(spinnerIcon, { attributes: true });

		projectHeader.appendChild(projectLabel);
		projectHeader.appendChild(spinnerIcon);
		projectHeader.appendChild(projectCheckbox);

		const filesContainer = document.createElement('div');
		filesContainer.classList.add('files-container');

		let isExpanded = false;
		projectHeader.addEventListener('click', (e) => {
			if (e.target === projectCheckbox || projectCheckbox.contains(e.target as Node)) return;
			isExpanded = !isExpanded;
			if (isExpanded) {
				filesContainer.style.display = 'block';
				filesContainer.style.maxHeight = 'none';
				const realHeight = filesContainer.scrollHeight;
				filesContainer.style.maxHeight = '0';
				filesContainer.style.overflow = 'hidden';
				filesContainer.style.transition = 'max-height 0.3s ease-out';
				void filesContainer.offsetHeight;
				filesContainer.style.maxHeight = `${realHeight}px`;
				setTimeout(() => {
					filesContainer.style.maxHeight = 'none';
					filesContainer.style.overflow = 'visible';
					this.adjustContainerHeight();
				}, 300);
			} else {
				const currentHeight = filesContainer.scrollHeight;
				filesContainer.style.maxHeight = `${currentHeight}px`;
				filesContainer.style.overflow = 'hidden';
				filesContainer.style.transition = 'max-height 0.3s ease-out';
				void filesContainer.offsetHeight;
				filesContainer.style.maxHeight = '0';
				setTimeout(() => {
					filesContainer.style.display = 'none';
					this.adjustContainerHeight();
				}, 300);
			}
			projectLabel.setAttribute('icon', isExpanded ? 'ep:arrow-down-bold' : 'ep:arrow-right-bold');
		});

		const nonDisciplineFiles = this.project.files.filter(file => !file.discipline_id);
		const sortedFiles = [...nonDisciplineFiles].sort((a, b) => a.name.localeCompare(b.name));
		for (const file of sortedFiles) {
			const fileUI = new FileUI(file, this.fileCheckboxes, this.fileSpinners, this.onFileCheckboxChange);
			filesContainer.appendChild(fileUI.createElement());
		}

		if (this.project.disciplines && this.project.disciplines.length > 0) {
			if (sortedFiles.length > 0) {
				const separator = document.createElement('div');
				separator.classList.add('separator');
				separator.style.borderTop = '1px dashed rgba(128, 128, 128, 0.2)';
				separator.style.margin = '5px 0';
				filesContainer.appendChild(separator);
			}

			const disciplinesContainer = document.createElement('div');
			disciplinesContainer.classList.add('disciplines-container');

			const sortedDisciplines = [...this.project.disciplines].sort((a, b) => a.name.localeCompare(b.name));
			for (const discipline of sortedDisciplines) {
				const disciplineUI = new DisciplineUI(
					discipline,
					this.fileCheckboxes,
					this.disciplineCheckboxes,
					this.fileSpinners,
					this.disciplineSpinners,
					this.onDisciplineCheckboxChange,
					this.onFileCheckboxChange,
					this.adjustContainerHeight
				);
				disciplinesContainer.appendChild(disciplineUI.createElement());
			}
			filesContainer.appendChild(disciplinesContainer);
		}

		projectContainer.appendChild(projectHeader);
		projectContainer.appendChild(filesContainer);
		return projectContainer;
	}


}